jQuery(function($){

  var msgMaxCount = 127;


  //===============================================
  /* ブロック要素に背景画像を設定 */
  //===============================================
  var d = document.getElementsByClassName("js-background");
  for(var i=0; i<d.length; i++){
    var f=d[i].getAttribute("data-src");
    d[i].style.backgroundImage="url('"+f+"')";
  };

  //===============================================
  /* メッセージをもっと読むボタンのクリック */
  //===============================================
  $('.js-btn_more').on('click', function(){
    var next = $(this).data('next');
    var inc = $(this).data('increment');
    var arr = [];
    //
    for(i=next; i<(next + inc); i++) {
      if(i <= msgMaxCount) {
        arr.push('./img/message/photo'+i+'.jpg');
      }
    }
    //非同期読み込み処理実行
    set_load_images_src(arr);
  });

});

//===============================================
/* スクロールの高さ取得 */
//===============================================
function getScrollHeight(tgt, height) {
  var minusheight = window.innerHeight - height;
  $(tgt).css('height', minusheight);
}

//===============================================
/* スクロール変更 */
//===============================================
function psUpdate(obj) {
    getScrollHeight('#content', minusHeight2);
    obj.update();
}

function is_smartphone(){
  return ($(window).width() < 768);
}


//===============================================
/* 非同期で画像を読み込む処理を実行し、メッセージのhtml文を追加する */
//===============================================
async function set_load_images_src(arr) {
  $('.loader_wrap').addClass('show');
  var html = '';
  var next = $('.js-btn_more').data('next');
  var inc = $('.js-btn_more').data('increment');
  const result = await load_images(arr);
  if(result.length > 0) {
    for (let i = 0, length = result.length; i < length; i++) {
      html += '<li><a href="'+result[i]+'" data-lightbox="msg'+(next+i)+'"><img src="'+result[i]+'"></a></li>\n';
    }
    $('.js-btn_more').data('next',next+inc);
    $('.msg_list').append(html);
    if(result.length < 8) {
      $('.js-btn_more').hide();
    }
  }
  $('.loader_wrap').removeClass('show');
}
//===============================================
/* 非同期で画像を読み込む */
//===============================================
function load_images(list){
  async function load(src){
      const img = new Image()
      img.src = src
      await img.decode()
      return img.src
  }
  return Promise.all(list.map(src => load(src)))
}